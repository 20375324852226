/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";

import AuthContext from "./context/Auth.context";
import { useContext } from "react";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
// eslint-disable-next-line no-unused-vars

const ResetPassword = React.lazy(() => import("./views/pages/login/ResetPassword"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const DailySmsShow = React.lazy(() => import("./views/pages/reports/dailysmsshow"));

function App() {
  const { Token } = useContext(AuthContext);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        {Token ? (
          <Routes>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route path="daily-sms" name="Home" element={<DailySmsShow />} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/*" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />

            <Route exact path="/reset" name="reset pass" element={<ResetPassword />} />
          </Routes>
        )}
      </Suspense>
    </HashRouter>
  );
}

export default App;
