/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

function AuthContextProvider(props) {
  const [Token, setToken] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [adminLogged, setadminLogged] = useState(false);

  // Cookies.set("admin", false, { expires: 1 / 24 });
  //         Cookies.set("sms_token", userData?.token, { expires: 1 / 24 });
  //         Cookies.set("sms_user_type", userData?.user_type, {
  //           expires: 1 / 24,
  //         });

  const checkToken = async () => {
    if (Cookies.get("admin") === true) {
      setadminLogged(true);
      setToken(Cookies.get("sms_token"));
      setUserRole(Cookies.get("sms_user_type"));
    } else {
      setadminLogged(false);
      setToken(Cookies.get("sms_token"));
      setUserRole(Cookies.get("sms_user_type"));
    }
    console.log("data", Cookies.get("adminLogged"));
  };

  useEffect(() => {
    checkToken();
    // setToken(Cookies.get("sms_token"));
    // setUserRole(Cookies.get("sms_user_type"));

    // if (Cookies.get("token") === undefined || Cookies.get("token") === null) {
    //   Cookies.remove("adminLogged", { path: "" });
    //   setToken("");
    //   setUserRole("");
    //   setadminLogged(false);
    // }
  }, []);

  return (
    <AuthContext.Provider value={{ Token, userRole, adminLogged }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
